

@font-face {
  font-family: 'Nineteenth';
  src: url('../fonts/Nineteenth.woff2') format('woff2'),
  url('../fonts/Nineteenth.woff') format('woff');
}

.header-container-text h1{
  font-family: Nineteenth;
  color: white;
  font-size: 3.5em;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
@media screen and (max-width: 700px){
  .header-container-text h1{
font-size:2.5em;
  }
  }
