
.card-body {
  background-color: #202428;
  .page-start
  {
    background-color: #202428;
  }
}
#page-content{
  background-color: #202428;
}

.booking-widget-container {
  margin-top: 55px;
  margin-bottom: -30px;
}

@media (max-width: 767px) {
  .booking-widget-container {
    margin-top: 54px;
  }
}