@font-face {
    font-family: 'Nineteenth';
    src: url('fonts/Nineteenth.woff2') format('woff2'),
    url('fonts/Nineteenth.woff') format('woff');
}

body {
    box-sizing: border-box;
    margin: 0;
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: gray;
}

.main-container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.navbar-wrapper {
    width: 100%;
    top: 0;
    height: 170px;
}

.navbar-container {
    position: relative;
    font-family: Nineteenth, sans-serif;
    display: flex;
    height: 100%;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
}

.navbar-logo-container {
    position: absolute;
    top: 0;
    width: 330px;
    height: 200px;
    z-index: 4;
}

.navbar-logo-container-img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 2;
    pointer-events: none;
}

.surfer-clicker-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 370px;
    height: 270px;
    object-fit: cover;
    z-index: 1;
}

.surfer-clicker-img:hover {
    transform: scale(1.05);
    cursor: pointer;
}

.navbar-background-container {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 200px;
    z-index: 0;
}

.navbar-background-container-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.navbar-right-container {
    position: absolute;
    top: 170px;
    right: 0;
    height: 75px;
    width: 450px;
    z-index: 3;
    display: flex;
    flex-direction: row;
}

.navbar-right-container-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.navbar-language-container {
    display: flex;
    flex-direction: row;
    z-index: 3;
    padding-left: 60px;
    width: 40%;
    height: 40px;
    position: absolute;
    margin-right: 10px;
}

.navbar-language-container img {
    width: 40px;
    height: 30px;
    margin-top: 5px;
    margin-right: 10px;
    cursor: pointer;
    filter: grayscale(100%);
}

.navbar-language-container img:hover
{
    filter: grayscale(0%);
}

.navbar-social-links-buttons {
    display: flex;
    flex-direction: row;
    position: absolute;
    right: 0;
    width: 50%;
    height: 30px;
    margin-right: 15px;
    margin-top: 5px;
    align-items: center;
    justify-content: space-between;
    padding-right: 30px;
    padding-left: 30px;
    cursor: pointer;
}

.navbar-social-links-insta:hover,
.navbar-social-links-fb:hover,
.navbar-social-links-phone:hover,
.navbar-social-links-mail:hover {
    transform: scale(1.1);

}

.pages-buttons-container {
    height: 120px;
    width: calc(100vw - 50px);
    margin-right: 50px;
    padding-left: 280px;
    padding-right: 150px;
    top: 40px;
    left: 0;
    background: gray;
    transform: translateX(-100%);
    transition: transform 0.5s ease-in-out;
    border-radius: 35px;
    position: absolute;
}

.pages-buttons-container.open {
    transform: translateX(0);
}

.pages-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-right: 10px;
}

.page-button {
    font-size: 20px;
    color: #FFFFFF;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 140px;
    height: 52px;
    background: transparent 0% 0% no-repeat padding-box;
    box-shadow: 0px 8px 16px #351F09;
    border-radius: 35px;
    opacity: 1;
    z-index: 99999;
}

.page-button-book {
    text-align: center;
    font-size: 20px;
    justify-content: center;
    display: flex;
    align-items: center;
    width: 140px;
    height: 52px;
    letter-spacing: -0.63px;
    border-radius: 35px;
    color: #0B0B0B !important; ;
    opacity: 1;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    text-decoration: none !important;
    transition: 0.3s ease-in-out;
}

.nav-link-book {
    text-decoration: none !important;
    color: #0B0B0B !important;
}

.page-button:hover {
    transform: scale(1.1);
    color: gold;
}

.page-button-book:hover {
    transform: scale(1.1);
    background: darkgoldenrod 0% 0% no-repeat padding-box;
}

.surfer-head {
    height: 130px;
    width: 130px;
    position: absolute;
    right: -40px;
    background: none;
}

.surfer-head-img {
    height: 100%;
    width: 100%;
    object-fit: fill;
}

.content-wrapper {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: darkgray;
}

.test-block {
    width: 100%;
    height: 300px;
}
.other-apartments-container{
    font-family: Nineteenth, sans-serif;

    width: 100%;
    left: 0;
    right: 0;
    height: 70px;
    background: linear-gradient(to right, #000000, #B87F2E);
    justify-content: flex-end;
    top: 210px;
    position: absolute;
    flex-direction: row;
    display: flex;
    gap: 20px;
    z-index: 2;
}

.other-apartments-first{
    color : #FFFFFF;
    font-size: 22px;
    flex-direction: row;
    z-index: 999;
    display: flex;
    align-items: center;
    padding-right: 20px;
    cursor: pointer;


}
.other-apartments-logo-first{
    height: 20px;
    width: 20px;
    object-fit: fill;
    margin-bottom: 5px;
    padding-right: 10px;
    z-index: 999;
}
.other-apartments-second{
    color : #FFFFFF;
    font-size: 22px;
    flex-direction: row;
    display: flex;
    align-items: center;
    padding-right: 20px;
    cursor: pointer;

}
.other-apartments-logo-second{
    height: 20px;
    width: 20px;
    object-fit: fill;
    margin-bottom: 5px;
    margin-right: 5px;}

.other-apartments-second:hover{
    transform: scale(1.1);
    filter: brightness(0);
    transition: 0.3s ease-in-out;
}
.other-apartments-first:hover{
    transform: scale(1.1);
    filter: brightness(0);
    transition: 0.3s ease-in-out;
}
.footer-wrapper {
    width: 100%;
    background-color: #000000;
    height: 310px;
    display: flex;
    bottom: 0;
    left: 0;
    right: 0;
}

@media screen and (max-width: 850px){
    .footer-wrapper {
        height:350px;
    }
}


.footer-container {
    display : flex;
    width: 100%;
    height: 100%;
    font-family: Nineteenth, sans-serif;
    flex-direction: column;
    position: relative;
}

.footer-friends-container {
    top: 0;
    left: 0;
    right: 0;
    height: 120px;
    width: 100%;
    background-color: #D29035;
    display: flex;
    flex-direction: row;
    padding-right: 150px;
    justify-content: space-between;
    align-items: center;
    color: #FFFFFF;
}

@media screen and (max-width: 850px){
    .footer-friends-container {
        width: 100%;
    }
}

.footer-friends-cava,
.footer-friends-anioly,
.footer-friends-nautica {
    flex: 1;
    text-align: center;
}

.footer-friends-cava-img,
.footer-friends-anioly-img,
.footer-friends-nautica-img {
    max-width: 80px;
    height: auto;
}

@media screen and (max-width: 850px){
    .footer-friends-cava-img,
    .footer-friends-anioly-img,
    .footer-friends-nautica-img {
        max-width: 50px;
    }
}

.footer-bandoc-pillow {
    height: 170px;
    width: 300px;
    top: 0;
    right: 0;
    position: absolute;
}

.footer-bandoc-pillow-img {
    height: 100%;
    object-fit: contain;
}

@media screen and (max-width: 850px){
    .footer-bandoc-pillow-img {
        position: absolute;
        height: 50%;
        left: 150px;
        object-fit: contain;
    }
}

.footer-bandoc-logo {
    top: 0;
    right: 0;
    position: absolute;
    width: 170px;
    height: 170px;
    z-index: 1;
}

.footer-bandoc-logo-img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

@media screen and (max-width: 850px){
    .footer-bandoc-logo-img {
        height: 50%;
        width: 150%;
        object-fit: contain;
    }
}

.footer-contact-container {
    height: 180px;
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #B87F2E;
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 850px){
    .footer-contact-container {
        height: 1000px;
        flex-direction: column;
    }
}

.footer-contact-container:hover {
    color: black;
}

.footer-texture {
    left: 0;
    right: 0;
    bottom: 0;
    height: 180px;
    width: 100%;
    position: absolute;
    z-index: 0;
}

.footer-texture-img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.footer-contact {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    z-index: 1;
    padding-left: 100px;
}

@media screen and (max-width: 768px) {
    .footer-contact {
        padding-left: 60px;
    }}


.footer-contact-text-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: fit-content;
    z-index: 1;
}

.footer-contact span {
    color: white;
    font-size: 20px;
    flex-direction: row;
}

.footer-contact-phone,
.footer-contact-mail {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.footer-contact-phone img,
.footer-contact-mail img {

    height: 20px;
    width: 20px;
    object-fit: fill;
    margin-right: 5px;
    margin-top: 3px;
}

.footer-social-links {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

.footer-social-links-text-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: fit-content;
    z-index: 1;
}

.footer-social-links img {
    height: 20px;
    width: 20px;
    object-fit: fill;
    margin-bottom: 5px;
    margin-right: 5px;
}

.footer-social-links span {
    font-size: 20px;
    color: #FFFFFF;
}

.footer-apartments {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    z-index: 1;
    padding-left: 100px;
}
.footer-apartments-text-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: fit-content;
    z-index: 1;
}
.apartments-logo-zakopane,
.apartments-logo-primosten {
    height: 20px;
    width: 20px;
    object-fit: fill;
    margin-bottom: 5px;
    margin-right: 5px;
}

.footer-apartments-primosten:hover {
    transform: scale(1.1);
    filter: brightness(0);
    cursor: pointer;

}

.footer-apartments-zakopane:hover {
    transform: scale(1.1);
    filter: brightness(0);
    cursor: pointer;

}

.footer-apartments span,
.footer-apartments a,
.footer-apartments .link {
    color: white;
    font-size: 20px;
    text-decoration: none;
    transition: color 0.3s ease, transform 0.3s ease;
}

.footer-social-links-insta,
.footer-social-links-fb {
    transition: filter 0.3s ease;
}

.footer-social-links-insta:hover,
.footer-social-links-fb:hover {
    filter: brightness(0);
    transform: scale(1.1);
    cursor: pointer;
}

.footer-contact-phone:hover,
.footer-contact-mail:hover {
    transform: scale(1.1);
    cursor: pointer;
    filter: brightness(0);
}

@media screen and (max-width: 768px) {
    .navbar-wrapper {
        height: 70px;
    }

    .navbar-logo-container {
        height: 100px;
        width: 160px;
        z-index: 4;
    }

    .surfer-clicker-container {
        position: relative;
        width: 175px;
        height: 135px;
    }

    .surfer-clicker-img {
        width: 100%;
        height: 100%;
    }

    .pointer-none {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 50%;
        pointer-events: none;
        z-index: 5;
    }

    .navbar-background-container {
        height: 100px;
        z-index: 2;
    }

    .pages-buttons-container {
        padding: 0;
        top: 0;
        right: 0;
        border-radius: 0;
        height: 100px;
        width: 100%;
        display: flex;
        align-items: center;
    }

    .pages-buttons-container.open {
        z-index: 3;
    }

    .pages-buttons {
        position: absolute;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        width: 100%;
        margin: 0;
        padding-left: 170px;
        padding-right: 1px;
    }



    .page-button {
        position: relative;
        width: 80px;
        height: 20px;
        font-size: 9px;
        justify-content: center;
        transform: rotate(-49deg);
        margin: 0;
        padding: 0;
        z-index: 9999;
    }

    .page-button:hover{
        transform: rotate(-49deg);
    }

    .page-button .nav-link {
        width: 60px;
        height: 20px;
        margin: 0;
        padding: 0;
        z-index: 999;
    }

    .nav-link{
        z-index: 99999;
    }

    .page-button-book .nav-link-book {
        width: 60px;
        height: 20px;

    }

    .page-button-book {
        color: #FFFFFF !important;
        position: fixed;
        top: 120px;
        width: 75px;
        height: 75px;
        font-size: 15px;
        justify-content: center;
        left: 5px;
        background-color: transparent;
        background-image: url("./assets/navbar/button-book-background-mobile.png");
        background-size: cover;
        border-radius: 0;
    }

    .nav-link-book {
        color: #FFFFFF !important;
        margin-top: 40px;
    }

    .surfer-head {
        display: none;
    }

    .navbar-right-container {
        top: 80px;
        transform: scale(0.5);
        transform-origin: right center;
        position: absolute;
        right: 0;
        z-index: 50;
    }

}
    .leafleat-container{
    position: relative;
   height: 100vh;
  }
@media screen and (max-width: 768px) {
    .other-apartments-container{
        height: 50px;
        top: 120px;
    }
    .other-apartments-second{
        font-size: 15px;
        padding-right: 5px;


    }
    .other-apartments-first{
        font-size: 15px;
        padding: 0;
    }
}

@media screen and (max-width: 600px) {
    .header-container{
        display: flex;
        padding-left: 10px;
        padding-right: 10px;

    }
}