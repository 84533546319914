.navbar-background-container{
  height: 300px;
}
.booking-container{
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 100px;
  background: linear-gradient(#000000, #6D6D6D, #101010);
  a{
    color: #1C1C1C00;
  }
}
@media screen and (max-width: 850px){
  .booking-container{
    padding: 50px 10px; 
    margin-top: 90px;
    flex-direction: column;
  }
}
@media screen and (max-width: 850px){
.navbar-background-container{
  height: 160px;
}
}