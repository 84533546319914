.awards-container {
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  }
  
  .awards-icons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 2000px;
  height: auto;
  position: relative; 
  transform: translateX(-100%); 
  transition: transform 1s ease-in-out; 
  
  .awards-booking,
  .awards-trip {
  height: 100%;
  width: 100%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  }
  }
  .awards-icons.show {
    transform: translateY(0%); 
  }
  
  @media screen and (max-width: 1170px) {
  .awards-container {
  height: 500px;
  }
  
  .awards-icons {
  height: 150%;
  .awards-booking,
.awards-trip {
  height: 60%;
  width: 50%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

}
}

@media screen and (max-width: 700px) {
.awards-container {
height: 300px;
}

.awards-icons {
height: 100%;
.awards-booking,
.awards-trip {
  height: 100%;
  width: 50%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
}
}