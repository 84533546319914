@font-face {
  font-family: 'Nineteenth';
  src: url('../fonts/Nineteenth.woff2') format('woff2'),
  url('../fonts/Nineteenth.woff') format('woff');
}




.apartment-card {
  font-family: Nineteenth;
  background: linear-gradient(0.25turn, #B3803E, #0D0B0B);
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 60px;

  .apartment-card-apartments{
    width: 100%;
    background: linear-gradient(0.25turn, #B3803E, #0D0B0B);
    height: 120px;
    padding-top: 100px;
    justify-content: space-evenly;
    align-items: center;
    display: flex;

  }

    .apartment-card-apartments-button-silver{
      font-size: 20px;
      text-transform: uppercase;
      color: #FFFFFF;
      cursor: pointer;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .apartment-card-apartments-button-silver:hover{
      color: #989898;
    }
    @media screen and (max-width: 850px){.apartment-card-apartments-button-silver{
      font-size:13px;

    img{
      height: 30%;
      width: 30%;
    }
  }
  }

    .apartment-card-apartments-button-gold{
      font-size: 20px;
      text-transform: uppercase;
      color: #FFFFFF;
      cursor: pointer;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .apartment-card-apartments-button-gold:hover{
color: #DBB27A;
    }
    @media screen and (max-width: 850px){.apartment-card-apartments-button-gold{
      font-size:13px;

    img{
      height: 30%;
      width: 30%;
    }
  }
  }
  .apartment-card-title {
    margin-top: 55px;
    padding: 20px;
    
    h3{
      font-size: 2.5em;
      color: white;
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);

    }
    p {
      font-size: 1.2em;
      color: white;
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    }
  }
  

  .apartment-card-content {
    display: flex;
    flex-direction: column;

    .apartment-card-content-up {
      display: flex;
      width: 100%;
      justify-content: space-evenly;


      .apartment-card-image {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: inherit;
        inset: 1px;
        padding: 1em;
        
        }
       
        img {
          max-width: 50%;
          object-fit: cover;
          object-position: center;
          border-radius: 0.75em;
          cursor: pointer;
        }
        
        img:hover {
          border: 1px solid #7A592D;
          opacity: 1;
          box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
        }
        @media screen and (max-width: 850px) {
          img {
          max-width: 80vw;
          margin-bottom: 20px;
          }
          }
      }

      .apartment-card-features-txt {
        flex: 1;
        display: flex;
        flex-direction: column;
        max-width: 50%;
        p{
          padding-left: 20px;
            font-size: 1em;
            color: white;
            text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
        }
      }
      @media screen and (max-width: 850px) {
        .apartment-card-features-txt {
        max-width: 100vw;
        }
      }
      

    .apartment-card-content-down {
      display: flex;
      flex-direction: column;
      width: 100%;
      flex: 1;
      justify-content: space-evenly;


      .apartment-card-content-highlight{
        display:flex;
        width: 100%;
        height: 100px;

      }

      .apartment-card-graphic{
        display: flex;
        width: 100%;
        height: 150px;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: #0B0B0B;
        img{
          max-width: 100%;
          object-fit: cover;
          object-position: center;
        }
      }


      }
    }
    .slick-slider {
      width:100%;
      height: 200px;
    }
      .slick-slide{
width:200px;
height: 200px;
      }
      .slick-list{
        height: 200px;
      }
  @media screen and (max-width: 850px) {
    .apartment-card-title {
      padding: 0 20px;
      text-align: center;
    }
  }
  @media screen and (max-width: 850px) {
    .apartment-card-image {
      flex-direction: column;
    }
}
}
.apartment-card-content-mid {
  display: flex;
  flex-wrap: wrap;
    width: 100%;

  .apartment-card-slick-slide {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 200px;

    
      img.apartment-card-small-image {
        flex-shrink: 0;
        width: 100%;
        object-fit: cover;
        filter: brightness(65%);
        transition: all 0.3s ease-in-out;
        height: 200px;
      }
        img.apartment-card-small-image:hover {
            filter: brightness(100%);
            transform: scale(1.2);
        }
    }

  }


@media screen and (max-width: 700px) {
  .apartment-card-content-mid {
    width: 100%;
    height: 200px;
    .apartment-card-slick-slide{
      width: 100%;
      img.apartment-card-small-image {
        width: 100%;
        filter: brightness(70%);
        transition: all 0.3s ease-in-out;
        height: 200px;
        align-content: center;
        justify-content: center;
      }}}}
@media screen and (max-width: 1170px) {
  .apartment-card-content-mid {
    .apartment-card-slick-slide {
      img.apartment-card-small-image {
        width: 100%;
        height: 200px;
      }}}}