.content-container{
  width:100%;
  height:100%;

}
.content-container_up{
  padding: 30px 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  width:100%;
  background: linear-gradient(0.25turn, #B3803E, #0D0B0B);
}
.header-container{
  margin-bottom: 10px;
  margin-top: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.apartment-card-block{
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}
@media screen and (max-width: 600px){
  .apartment-card-block{
    flex-direction: column-reverse;
  }
}
 
.apartment-card-block-left-side{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1;
}
.apartment-card-block-right-side{
  display: flex;
  justify-content: center;
  align-items: center;
    flex: 1;
}


@media screen and (max-width: 1000px){
  .content-container_up{
font-size: 0.5em;
  }
}
.content-container_mid{
  display: flex;
  flex-direction: column;
  width:100%;
}
.content-container_lower{
  display: flex;
  flex-direction: column;
  width:100%;
  height:1100px;
  background: linear-gradient(#101010, #6D6D6D, #101010);
}
@media screen and (max-width: 850px){
  .content-container_lower{
  height:1000px;
  }
}
@media screen and (max-width: 650px){
  .content-container_lower{
  height:700px;
  }
}

.content-container_bottom{
  height: 130px;
  background-image: url(../assets/review/review_bg.png);
background-size: cover;
}
.content-container_bottom{
  margin-top: -20px;
  height: 150px;
}
@media screen and (max-width: 1440px){
  .content-container_bottom{
    margin: -10px 0;
    height: 150px;
    
  }
  }