.leafleet-map {
  margin: 2rem 0;
  position: relative;
  width: 50%;
  height: 400px;
  margin-bottom: 2rem;
}

.map {
  height: 400px;

}
.leaflet-container {
  height: 100%;
  width: 100%;
}


.leaflet-popup-content-wrapper {
  background-color: #fff;
  color: #000;
}


.leaflet-popup-tip-container {
  display: none;
}


.btn {
  font-size: 1.2rem;
  color: #fff;
  background-color: var(--gold);
  border: none;
  border-radius: 4px;
  color: var(--black);
  border-radius: 0.3rem;
  padding: 1rem 2rem;
  cursor: pointer;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  position: absolute;
  right: 1rem;
  top: 1rem;
  transition: background-color 0.2s ease-in-out;
}

.btn:hover {
  background-color: var(--silver);
  color: var(--black);
}

.btn:hover {
  background-color: var(--gray);
}
.leaflet-marker-pane img{
  height: 100px;
  width: 100px;
}