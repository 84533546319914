@font-face {
  font-family: 'Nineteenth';
  src: url('../fonts/Nineteenth.woff2') format('woff2'),
  url('../fonts/Nineteenth.woff') format('woff');
}

.invitation-container {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-left: 80px;
  padding-right: 0;
  padding-top: 50px;
}
@media screen and (max-width: 700px){
  .invitation-container {
   padding: 20px 40px;
  }
}

.invitation-container-text {
  text-align: justify;
  text-justify: inter-word;
  width: 100%;
}

.invitation-container-text h2 {
  font-family: Nineteenth;
  color: white;
  font-size: 1.2em;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);

}
