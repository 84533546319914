.content-slider{
  position: relative;
  width:450px !important;
  height: 400px !important;
  box-shadow: 0px 4px 15px rgba(173, 123, 59, 0.9);
  border-radius:20px;


  img{
  width:450px !important;
  height: 400px !important;
  border-radius:20px;

  }
}
@media screen and (max-width: 1170px){
  .content-slider{
    width:260px !important;
    height: 220px !important;

    img{
    width: 260px !important;
    height: 220px !important;
    }
}
}
@media screen and (max-width: 700px){
  .content-slider{
    width: 250px !important;
    height: 200px !important;
    img{
    width: 250px !important;
    height: 200px !important;
    }
}
}